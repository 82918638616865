<template>
  <div class="q-pa-md">
    <div class="row">
      <div class="col-12 moc-complete">
        <!-- 변경완료의견 -->
        <c-textarea
          :disabled="true"
          :editable="editable"
          label="LBL0001973"
          name="mocCompleteOpinion" 
          v-model="moc.mocCompleteOpinion">
        </c-textarea>
      </div>
    </div>
    <q-timeline class="moc-timeline" color="green-4">
      <q-timeline-entry
        v-for="(item, idx) in items"
        :key="idx"
        :heading="item.heading"
        :class="[(idx===0 ? 'fix-header' : '')]"
        :icon="item.icon">
        <template v-if="item.title" v-slot:title>
          <b>{{item.title}}</b>
        </template>
        <template v-if="item.subtitle" v-slot:subtitle>
          {{item.subtitle}}
        </template>
        <q-slide-transition v-if="!item.heading">
          <div v-show="item.visible">
            <component
              :is="item.component"
              :param.sync="param"
              :moc.sync="moc"
              :disabled="true"
            />
          </div>
        </q-slide-transition>
      </q-timeline-entry>
    </q-timeline>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'moc-onepage-emergency',
  components: {
  },
  props: {
    param: {
      type: Object,
      default: () => ({
        sopMocId: '',
        mocTypeCd: '',
        mocStepCd: '',
        committeeHold: {
          sopMocId: '',  // MOC 일련번호
          changeApprovalFlag: '',  // 변경 승인 여부
          changeApprovalReason: '',  // 변경 승인 사유
          changeApprovalDate: '',  // 승인일
        },
      }),
    },
  },
  data() {
    return {
      editable: true,
      moc: {
        sopMocId: '',  // MOC 일련번호
        mocNo: '',  // moc 관리번호
        plantCd: '',  // 사업장 코드
        initiativeUserId: '',  // 발의자 ID
        initiativeUserName: '',  // 발의자 명
        initiativeDeptCd: '',  // 발의 부서 코드
        initiativeDeptName: '',  // 발의 부서명
        mocTitle: '',  // 변경관리 제목
        mocSummary: '',  // 변경 개요
        mocTypeCd: 'MT00000001',  // 변경구분_공통코드(정상/비상/임시)
        mocWriteLevelCd: null,  // 변경등급_작성
        mocConfirmLevelCd: null,  // 변경등급_검토
        processSatefySecureMeasures: '',  // 공정안전 확보 대책
        technicalBasisProcessDesign: '',  // 변경계획에 대한 공정 및 설계의 기술근거
        safetyReliabilityImprovementEffect: '',  // 안전성에 필요한 사항 및 신뢰성 향상 효과
        leaderOpinion: '',  // 팀장의견
        relatedLaws: '',  // 관련 볍령_텍스트
        processRiskAssessFlag: '',  // 공정위험성 평가 여부
        jobRiskAssessFlag: '',  // 작업위헝성 평가 여부
        mocPeriod: [],
        mocStartDate: '',  // 변경 시작일
        mocEndDate: '',  // 변경 종료일
        mocStepCd: '',  // 변경관리 단계
        mocActionDeptCd: '',  // 변경실행 부서코드
        mocActionResponsibilityUserId: '',  // 변경실행 책임자 ID
        mocCompleteCheckUserId: '',  // 변경완료 확인자 ID
        mocCompleteCheckDeptCd: '',  // 변경완료 확인 부서 코드
        mocCompleteOpinion: '',  // 변경완료 의견
        mocBenefitCds: '',  // 변경관리 기대효과_복수
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        checklists: [],
        checklistEquips: [],
        checklistDrives: [],
        equipments: [],
        deleteEquipments: [],
        reviews: [],
        committees: [],
        committeeReviewItems: [],
        committeeHold: {
          sopMocId: '',  // MOC 일련번호
          firstHoldDate: '',  // (최초) 개최일
          firstHoldArea: '',  // (최초) 개최장소
          firstReviewContents: '',  // (최초) 검토내용
          firstReviewResult: '',  // (최초) 검토결과
          professionalHoldDate: '',  // (전문가) 개최일
          professionalHoldArea: '',  // (전문가) 개최장소
          professionalReviewContents: '',  // (전문가) 검토내용
          professionalReviewResult: '',  // (전문가) 검토결과
          finalHoldDate: '',  // (최종) 개최일
          finalHoldArea: '',  // (최종) 개최장소
          finalReviewContents: '',  // (최종) 검토내용
          finalReviewResult: '',  // (최종) 검토결과
          changeApprovalFlag: '',  // 변경 승인 여부
          changeApprovalReason: '',  // 변경 승인 사유
          changeApprovalDate: '',  // 승인일
          changeKeepFlag: '',  // 변경유지 여부
          changeKeepReason: '',  // 변경유지 사유
          regUserId: '',  // 등록자 ID
          chgUserId: '',  // 수정자 ID
        },
      },
      detailUrl: '',
      items: [
        // 발의
        { title: this.$comm.getLangLabel('LBL0002013'), visible: true, component: () => import(`${'./mocRequest.vue'}`) },
        // 발의 전 검토
        { title: this.$comm.getLangLabel('LBL0002014'), visible: true, component: () => import(`${'./reviewItem.vue'}`) },
        // 검토(위원회)
        { title: this.$comm.getLangLabel('LBL0002015'), visible: true, component: () => import(`${'./timeline/mocMeetingTL.vue'}`) },
        // 실시
        { title: this.$comm.getLangLabel('LBL0002016'), visible: true, component: () => import(`${'./timeline/taskProceedingTL.vue'}`) },
        // 사후처리
        { title: this.$comm.getLangLabel('LBL0002029'), visible: true, component: () => import(`${'./timeline/mocImprTabTL.vue'}`) },
      ],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.sop.moc.change.initiative.get.url
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.param.sopMocId) {
        this.$http.url = this.$format(this.detailUrl, this.param.sopMocId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.extend(this.moc, _result.data);
          this.moc.mocPeriod = this.moc.mocStartDate && this.moc.mocEndDate ?
            [this.moc.mocStartDate, this.moc.mocEndDate] : []
        },);
      }
    },
  }
};
</script>
<style lang="sass">
.moc-timeline
  max-height: 550px
  .q-timeline__heading-title
    padding-bottom: 10px
.moc-complete .customTextArea.q-field--dense .q-field__inner
  padding-top: 20px !important
  padding-bottom: 2px !important
</style>